const d = {
  BACKEND_API_URL: 'https://api.csckuendigen.de/v1',
  /*REACT_APP_STRIPE_PUBLIC_KEY: 'pk_test_51Pa0cYIrW2Sgc0Ri83CoESzbVuZD3soYn6ftcdxCkMS8HIVvqRigr0ZIbnieidyQf2pd9Q7IP8NuFrYpWKn4fdsY00VOOltAGf',*/
  REACT_APP_STRIPE_PUBLIC_KEY: 'pk_live_51Pa0cYIrW2Sgc0RiSxkJCSLgwtHXbKYeEzTo78Urv6AEJPYbCVpP3HzE9owlIdMiCaEQPWB1SyZwv0j6e5lGVRKP00Mjdly3ji',
  letterTypes: [
    {
      name: 'Email mit Anwalt in Kopie',
      priceEuro: 9.99,
      needsAddress: false
    },
    {
      name: 'Email & Brief mit Anwalt in Kopie',
      priceEuro: 19.99,
      needsAddress: true
    },
    {
      name: 'Email & Einschreiben mit Rückschein und Anwalt in Kopie',
      priceEuro: 99.99,
      needsAddress: true
    }
  ]
}

const cfg = key => (key == null ? d : d[key])

const formatEuro = (value) => {
  const number = typeof value === 'string' ? parseFloat(value) : value;
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
};

const fetchVereineWithCache = async () => {
  try {
    // Check localStorage first
    const cachedData = localStorage.getItem('vereine');
    const cachedTimestamp = localStorage.getItem('vereineTimestamp');
    
    // Cache is valid for 24 hours
    const CACHE_DURATION = 86400000;
    const isValidCache = cachedData && cachedTimestamp && 
      (Date.now() - parseInt(cachedTimestamp) < CACHE_DURATION);

    if (isValidCache) {
      return JSON.parse(cachedData);
    }

    // Fetch fresh data if cache is invalid
    const response = await fetch(`${cfg('BACKEND_API_URL')}/vereine`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    
    // Update cache
    localStorage.setItem('vereine', JSON.stringify(data));
    localStorage.setItem('vereineTimestamp', Date.now().toString());
    
    return data;
  } catch (error) {
    console.error('Error fetching the vereine:', error);
    return [];
  }
};

export { cfg, formatEuro, fetchVereineWithCache };