import React from 'react';
import { Box, Container, Typography, Link, Grid } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: 'background.paper', p: 6, mt: 4 }} component="footer">
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center">
          <Grid item>
            <Link href="/impressum" variant="body2" color="textSecondary">
              Impressum
            </Link>
          </Grid>
          <Grid item>
            <Link href="/datenschutz" variant="body2" color="textSecondary">
              Datenschutz
            </Link>
          </Grid>
          <Grid item>
            <Link href="/agb" variant="body2" color="textSecondary">
              AGBs
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'© '}
            CSCkuendigen.de ({new Date().getFullYear()})
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
