import React from 'react';
import { Button } from '@mui/material';

const SubmitButton = ({ isFormValid, onSubmit }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      disabled={!isFormValid}
      onClick={onSubmit}
      fullWidth
    >
      Kündigung starten
    </Button>
  );
};

export default SubmitButton;
