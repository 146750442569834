import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import HomePage from './HomePage';
import PaymentPage from './PaymentPage';
import SealPage from './SealPage';
import VereinePage from './VereinePage';
import VereinPage from './VereinPage'; // Import the new page
import LetterFinalPage from './LetterFinalPage';
import ImprintPage from './ImprintPage';
import PrivacyPage from './PrivacyPage';
import AgbPage from './AgbPage';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
  palette: {
    primary: {
      light: '#81946e',
      main: '#627a4a',
      dark: '#445533',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  typography: {
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.3rem',
    },
    h5: {
      fontSize: '1.15rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/zahlung" element={<PaymentPage />} />
            <Route path="/kuendigung-abgeschlossen" element={<LetterFinalPage />} />
            <Route path="/vereine" element={<VereinePage />} />
            <Route path="/verein/:urlName" element={<VereinPage />} />
            <Route path="/siegel" element={<SealPage />} />
            <Route path="/impressum" element={<ImprintPage />} />
            <Route path="/datenschutz" element={<PrivacyPage />} />
            <Route path="/agb" element={<AgbPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
