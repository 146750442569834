import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import TopBar from './TopBar';
import Footer from './Footer';

const AgbPage = () => {
  // const location = useLocation();
  // const { name, memberid, address, zip, city, email, letterType, verein, cost } = location.state || {};

  return (
    <>
      <TopBar />
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={4}>
          <Typography variant="h1" gutterBottom>
            AGBs
          </Typography>
          
        </Box>
        <Footer />
      </Container>
    </>
  );
};

export default AgbPage;
