import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button, CircularProgress, TextField, Box, Typography } from '@mui/material';
import { cfg } from './util';
import CardSection from './CardSection';

const PaymentForm = ({ name, memberid, address, zip, city, email, letterType, verein, vereinId, cost }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setPaymentStatus('');

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const response = await fetch(`${cfg('BACKEND_API_URL')}/letter/payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          amount: cost,
          name,
          memberid,
          email,
          address,
          zip,
          city,
          letterType,
          verein,
          vereinId
        })
      });

      const { clientSecret } = await response.json();

      // Confirm the payment on the client
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name,
            address: { line1: address, city, postal_code: zip },
            email
          }
        }
      });

      if (stripeError) {
        setError(stripeError.message);
      } else if (paymentIntent && paymentIntent.status && paymentIntent.status === 'succeeded') {
        setPaymentStatus('Payment successful!');
        navigate('/kuendigung-abgeschlossen');
      } else {
        setError('Payment fraglich?');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('Payment failed. Please try again.');
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <TextField fullWidth label="Verein" variant="outlined" value={verein} disabled />
      </Box>
      <Box mb={2}>
        <TextField fullWidth label="Dein Vorname und Nachname" variant="outlined" value={name} disabled />
      </Box>
      <Box mb={2}>
        <TextField fullWidth label="Deine Email-Adresse" variant="outlined" value={email} disabled />
      </Box>
      <Box mb={2}>
        <TextField fullWidth label="Vertrags- oder Mitgliedsnummer" variant="outlined" value={memberid} disabled />
      </Box>
      <Box mb={2}>
        <TextField fullWidth label="Deine Adresse" variant="outlined" value={address} disabled />
      </Box>
      <Box mb={2}>
        <TextField fullWidth label="Deine Postleitzahl" variant="outlined" value={zip} disabled />
      </Box>
      <Box mb={2}>
        <TextField fullWidth label="Dein Wohnort" variant="outlined" value={city} disabled />
      </Box>
      <Box mb={2}>
        <TextField fullWidth label="Briefvariante" variant="outlined" value={cfg('letterTypes')[letterType].name} disabled />
      </Box>
      <Box mb={2}>
        <TextField fullWidth label="Preis" variant="outlined" value={`${cost} €`} disabled />
      </Box>
      <Box mb={2}>
        <Typography variant="h5" align='left' gutterBottom>
          Kartenzahlung
        </Typography>
      </Box>
      <CardSection />
      {error && <Box color="error.main">{error}</Box>}
      {paymentStatus && <Box color="success.main">{paymentStatus}</Box>}
      <Box mb={2} mt={2}>
        <Button type="submit" variant="contained" color="primary" disabled={!stripe || loading || paymentStatus.length > 0} fullWidth>
          {loading ? <CircularProgress size={24} /> : 'Bezahlen'}
        </Button>
      </Box>
    </form>
  );
};

export default PaymentForm;
