import React from 'react';
import { Helmet } from 'react-helmet-async';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TopBar from './TopBar';

const SealPage = () => {
  return (
    <>
      <Helmet>
        <title>CSC Kündigen - Siegel</title>
        <meta name="description" content="Erfahre mehr über unser CSC Kündigen Siegel." />
      </Helmet>
      <TopBar />
      <Container maxWidth="md">
        <Box textAlign="center" mb={6}>
            <img 
              src='/logo/csc-kuendigen-siegel-logo.jpg' 
              alt="CSC Kündigen Siegel Logo" 
              style={{ borderRadius: '8px', height: '200px', marginTop: 30 }} 
            />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Typography variant="h1" sx={{ fontSize: '2.5rem', textAlign: 'center', mb: 2 }}>
            Das CSC Kündigen Siegel
          </Typography>
          <Typography variant="h2" sx={{ fontSize: '1.5rem', textAlign: 'center', color: 'text.secondary', mb: 3 }}>
            Vereine mit dem CSC Kündigen Siegel stehen für Qualität und Zuverlässigkeit
          </Typography>
        </Box>
        <Box mb={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h2" gutterBottom>
              Kriterien für das Siegel
            </Typography>
            <Typography variant="body1" paragraph>
              Unser Siegel wird nur an CSCs vergeben, die unsere strengen Qualitätskriterien erfüllen. Dazu gehören:
            </Typography>
            <ul>
              <li><Typography variant="body1">Rechtssichere Kündigungsvorlagen</Typography></li>
              <li><Typography variant="body1">Schnelle Bearbeitung der Kündigungen</Typography></li>
              <li><Typography variant="body1">Hohe Kundenzufriedenheit</Typography></li>
            </ul>
          </Paper>
        </Box>
        <Box mb={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h2" gutterBottom>
              Vorteile des Siegels
            </Typography>
            <Typography variant="body1" paragraph>
              CSCs mit unserem Siegel bieten ihren Mitgliedern zahlreiche Vorteile:
            </Typography>
            <ul>
              <li><Typography variant="body1">Interessierte wissen, welcher Verein fair ist bei der Kündigung</Typography></li>
              <li><Typography variant="body1">Vereine erhalten mehr Vertrauen bei Interessierten</Typography></li>
              <li><Typography variant="body1">Einfaches Kündigungsverfahren</Typography></li>
              <li><Typography variant="body1">Weniger Beschwerden</Typography></li>
              <li><Typography variant="body1">Keine unnötigen Gebühren</Typography></li>
              <li><Typography variant="body1">Schnelle Kündigung</Typography></li>
            </ul>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default SealPage;