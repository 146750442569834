import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';

const LetterFinalPage = () => {
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={4}>
        <CheckCircleOutlineIcon color="success" style={{ fontSize: 50 }} />
        <Typography variant="h4" gutterBottom>
          Abgeschlossen!
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Deine Kündigung wurde an den Verein verschickt.
          <br />
          Die Rechnung für unseren Service erhälst du per Email.
        </Typography>
        <Button variant="contained" color="primary" href="/" style={{ marginTop: '20px' }}>
          Zurück zur Startseite
        </Button>
      </Box>
    </>
  );
};

export default LetterFinalPage;
