import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Box } from '@mui/material';
import './CardSection.css';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: '#000',
      color: '#000',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      }
    },
    invalid: {
      iconColor: '#f44336',
      color: '#f44336',
    },
  },
  hidePostalCode: true
};

const CardSection = () => (
  <Box className="card-section">
    <CardElement options={CARD_ELEMENT_OPTIONS} />
  </Box>
);

export default CardSection;
