import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  return (
    <Box py={5}>
      <Typography variant="h2" gutterBottom>
        Häufig gestellte Fragen (FAQ)
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Wie kündige ich meine Mitgliedschaft in einem CSC?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Um deine Mitgliedschaft zu kündigen, wähle deinen Cannabis Social Club (CSC) oder Anbauverein aus unserer Datenbank und fülle das Formular aus. Sobald die Zahlung erfolgreich war, beginnen wir mit dem Versand des Briefs. Du erhältst eine Bestätigung und Rechnung per E-Mail.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Welche Informationen benötige ich für die Kündigung?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Du benötigst deinen Namen, deine Adresse, die Mitgliedsnummer (optional, falls vorhanden) und die E-Mail-Adresse, die mit deiner Mitgliedschaft verbunden ist.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Wie lange dauert es, bis meine Kündigung wirksam wird?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Die Wirksamkeit der Kündigung hängt von den jeweiligen Vertragsbedingungen deines CSC bzw. Anbauvereins ab. In der Regel erhältst du jedoch innerhalb weniger Tage eine Bestätigung.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Was passiert, wenn ich keine Kündigungsbestätigung erhalte?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Solltest du keine Bestätigung erhalten, kontaktiere bitte unseren Support oder spreche direkt mit deinem Verein. Wir helfen dir, sicherzustellen, dass deine Kündigung korrekt verarbeitet wurde.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Kann ich meine Mitgliedschaft jederzeit kündigen?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Die Kündigungsbedingungen können je nach CSC variieren. Oft gibt es Kündigungsfristen von 3 Monaten. Überprüfe deine Vertragsbedingungen, um sicherzustellen, dass du alle Fristen und Anforderungen einhältst.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ