import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { HashLink } from 'react-router-hash-link';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import VereinSelector from './VereinSelector';
import TerminateForm from './TerminateForm';
import TopBar from './TopBar';
import SubmitButton from './SubmitButton';
import Footer from './Footer';
import FAQ from './FAQ';
import { cfg, fetchVereineWithCache } from './util';
import { useTheme } from '@mui/material/styles';

const HomePage = () => {
  const location = useLocation();
  const [verein, setVerein] = useState('');
  const [vereine, setVereine] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    memberid: '',
    address: '',
    zip: '',
    city: '',
    email: '',
    letterType: ''
  });
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [vereinAddressValid, setVereinAddressValid] = useState(location.state?.vereinAddressValid || false);
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const loadVereine = async () => {
      const data = await fetchVereineWithCache();
      setVereine(data);
    };
    loadVereine();

    if (location.state && location.state.verein) {
      setVerein(location.state.verein);
    }

    if (location.hash === '#formular') {
      const element = document.getElementById('formular');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash, location.state]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleVereinChange = (event) => {
    const selectedVerein = event.target.value;
    setVerein(selectedVerein);
    
    // Check if the selected verein has a valid address using the addressValid flag
    const selectedVereinData = vereine.find(v => v.name === selectedVerein);
    setVereinAddressValid(selectedVereinData ? selectedVereinData.addressValid : false);
  };

  const isFormValid = () => {
    return (
      verein &&
      formData.name &&
      formData.address &&
      formData.zip &&
      formData.city &&
      formData.letterType &&
      isEmailValid
    );
  };

  const getLetterPrice = (letterType) => {
    const types = cfg('letterTypes');
    return types[parseInt(letterType)].priceEuro;
  };

  const handleSubmit = async () => {
    if (isFormValid()) {
      try {
        const cost = getLetterPrice(formData.letterType);
        const selectedVerein = vereine.find(v => v.name === verein);
        navigate('/zahlung', { 
          state: { 
            ...formData, 
            verein, 
            vereinId: selectedVerein ? selectedVerein.id : null,
            cost 
          } 
        });
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>CSC & Anbauverein Kündigung Online | Schnell & Einfach</title>
        <meta 
          name="description" 
          content="✓ CSC & Anbauverein Kündigung in 5 Minuten ✓ Über 500 geprüfte Kündigungsvorlagen ✓ Sofortiger Versand ✓ Professionelle Unterstützung" 
        />
        <meta 
          name="keywords" 
          content="CSC Kündigen, Anbauverein Kündigung, Cannabis Social Club kündigen, Mitgliedschaft beenden, CanG Vereinswechsel" 
        />
        <link rel="canonical" href="https://csckuendigen.de" />
        <meta property="og:title" content="CSC & Anbauverein Kündigung Online | Schnell & Einfach" />
        <meta property="og:description" content="CSC & Anbauverein Kündigung in 5 Minuten. >500 geprüfte Kündigungsvorlagen mit Sofortversand." />
        <meta property="og:image" content="/logo/csckuendigen-logo.jpg" />
        <meta property="og:url" content="https://csckuendigen.de" />
      </Helmet>
      <TopBar />
      <Container maxWidth="md">
        <Box textAlign="center" mb={6}>
          <img 
            src='/logo/csckuendigen-logo.jpg' 
            alt="CSC Kündigen - Professionelle Kündigungsvorlagen" 
            style={{ borderRadius: '8px', height: '200px', marginTop: 30, marginBottom: 30 }} 
          />
          
          <Typography variant="h1" component="h1" gutterBottom sx={{ fontSize: '2.5rem', fontWeight: 'bold' }}>
            Cannabis Social Club (CSC) & Anbauverein Kündigung Online
          </Typography>
          <Typography variant="h2" component="h2" sx={{ fontSize: '1.5rem', mb: 2 }}>
            In nur 5 Minuten professionell kündigen mit <span style={{ color: theme.palette.primary.main }}>csckuendigen.de</span>
          </Typography>
          <Typography variant="body1">
           {vereine.length} geprüfte Kündigungsvorlagen - Sofort online versenden 
          </Typography>
        </Box>
        <Typography variant="h2" gutterBottom>
          Warum CSC kündigen mit unserem Service?
        </Typography>
        <Box mb={6}>
          <ul>
            <li><Typography variant="body1">Einfach und Schnell: Wähle deinen CSC aus, fülle unser Formular aus und sende deine Kündigung in wenigen Minuten ab.</Typography></li>
            <li><Typography variant="body1">Rechtssicherheit: Unsere Kündigungsvorlagen sind rechtlich geprüft und helfen, dass deine Kündigung wirksam ist.</Typography></li>
            <li><Typography variant="body1">Rundum Service: Wir übernehmen die manuelle Arbeit wie Druck und Versand.</Typography></li>
          </ul>
        </Box>
        <Box mb={8} textAlign="center">
          <Button color="primary" variant="contained" component={HashLink} smooth to="#formular">
            Jetzt Kündigen
          </Button>
        </Box>
        <Typography variant="h2" gutterBottom>
          So funktioniert's:
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><Typography variant="h3">1. CSC auswählen</Typography></TableCell>
                <TableCell><Typography variant="body1">Finde deinen CSC in unserer umfangreichen Datenbank.</Typography></TableCell>
              </TableRow>
              <TableRow>
                <TableCell><Typography variant="h3">2. Formular ausfüllen</Typography></TableCell>
                <TableCell><Typography variant="body1">Nutze unsere rechtssicheren Vorlagen, um deine Kündigung vorzubereiten.</Typography></TableCell>
              </TableRow>
              <TableRow>
                <TableCell><Typography variant="h3">3. Kündigung abschicken</Typography></TableCell>
                <TableCell><Typography variant="body1">Sende deine Kündigung direkt online ab und erhalte eine Bestätigung.</Typography></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={3} mb={8}>
          <Typography variant="h5" gutterBottom>
            Wichtig für Mitglieder von Anbauvereinen
          </Typography>
          <Typography variant="body1">
            Laut CanG darfst du nur Mitglied in einem Anbauverein gleichzeitig sein. Nutze unseren Service, um schnell und einfach zwischen Anbauvereinigungen wechseln zu können.
          </Typography>
        </Box>
        <Box mt={3} mb={8} id="formular">
          <Typography variant="h2" gutterBottom>
            Jetzt Anbauverein oder CSC einfach kündigen
          </Typography>
          <Typography variant="body1" paragraph>
            Mit unserem Service hast du die Kontrolle über deine Mitgliedschaften. Probiere es aus und erlebe, wie einfach das Kündigen sein kann. Starte jetzt und beende deine CSC-Mitgliedschaft mit wenigen Klicks!
          </Typography>
          <Box mt={3}>
            <Paper sx={{ p: 3 }}>
              <Box mt={2}>
                <Typography variant="h5" gutterBottom>Wählen deinen Verein zur Kündigung aus</Typography>
              </Box>
              <VereinSelector vereine={vereine} value={verein} onChange={handleVereinChange} />
              <Box mt={2}>
                <Typography variant="h5" gutterBottom>Deine Daten</Typography>
              </Box>
              <TerminateForm 
                formData={formData} 
                vereinAddressValid={vereinAddressValid} 
                onInputChange={handleInputChange} 
                setFormValid={setIsEmailValid} 
              />
              <Box mt={2}>
                <SubmitButton isFormValid={isFormValid()} onSubmit={handleSubmit} />
              </Box>
            </Paper>
          </Box>
        </Box>
        <FAQ />
        <Footer />
      </Container>
    </>
  );
};

export default HomePage;
