import React from 'react';
import Container from '@mui/material/Container';
import { Typography, Box } from '@mui/material';
import TopBar from './TopBar';
import Footer from './Footer';

const ImprintPage = () => {
  // const location = useLocation();
  // const { name, memberid, address, zip, city, email, letterType, verein, cost } = location.state || {};

  return (
    <>
      <TopBar />
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={4}>
          <Typography variant="h1" gutterBottom>
            Impressum
          </Typography>
          <Typography variant="h2" gutterBottom mt={4}>
            Diensteanbieter
          </Typography>
          <Typography variant="body">
            3Affen GmbH<br />
            Kirchboden 40<br />
            6123 Vomperbach<br />
            Österreich
          </Typography>
          <Typography variant="body" gutterBottom>
            E-Mail-Adresse: kontakt@3affen.com
          </Typography>
          <Typography variant="h2" gutterBottom mt={4}>
            Haftungs- und Schutzrechtshinweise
          </Typography>
          <Typography variant="body" gutterBottom>
            Haftungsausschluss: Die Inhalte dieses Onlineangebotes wurden sorgfältig und nach unserem aktuellen Kenntnisstand erstellt, dienen jedoch nur der Information und entfalten keine rechtlich bindende Wirkung, sofern es sich nicht um gesetzlich verpflichtende Informationen (z.B. das Impressum, die Datenschutzerklärung, AGB oder verpflichtende Belehrungen von Verbrauchern) handelt. Wir behalten uns vor, die Inhalte vollständig oder teilweise zu ändern oder zu löschen, soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind freibleibend und unverbindlich.
            <br /><br />
            Links auf fremde Webseiten: Die Inhalte fremder Webseiten, auf die wir direkt oder indirekt verweisen, liegen außerhalb unseres Verantwortungsbereiches und wir machen sie uns nicht zu Eigen. Für alle Inhalte und Nachteile, die aus der Nutzung der in den verlinkten Webseiten aufrufbaren Informationen entstehen, übernehmen wir keine Verantwortung.
            <br /><br />
            Urheberrechte und Markenrechte: Alle auf dieser Website dargestellten Inhalte, wie Texte, Fotografien, Grafiken, Marken und Warenzeichen sind durch die jeweiligen Schutzrechte (Urheberrechte, Markenrechte) geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren Rechten oder den Rechten der jeweiligen Urheber bzw. Rechteinhaber.
            <br /><br />
            Hinweise auf Rechtsverstöße: Sollten Sie innerhalb unseres Internetauftritts Rechtsverstöße bemerken, bitten wir Sie uns auf diese hinzuweisen. Wir werden rechtswidrige Inhalte und Links nach Kenntnisnahme unverzüglich entfernen.
          </Typography>
          <Typography variant="h2" gutterBottom mt={4}>
            Vorbehalt der Nutzung für Text und Data Mining
          </Typography>
          <Typography variant="body" gutterBottom>
            Der Inhaber dieser Website gestattet die Nutzung oder das Herunterladen von Inhalten dieser Website durch Dritte für die Entwicklung, das Training oder den Betrieb von künstlicher Intelligenz oder anderen maschinellen Lernsystemen ("Text und Data Mining") ausschließlich mit ausdrücklicher schriftlicher Zustimmung des Inhabers. Ohne eine solche Zustimmung ist es untersagt, die Inhalte für Text und Data Mining zu verwenden. Dies gilt auch, wenn auf der Website keine Meta-Angaben vorhanden sind, die entsprechende Verfahren aussperren, und selbst dann, wenn Bots, die den Zweck haben, die Website zu Zwecken des Text und Data Mining auszulesen, nicht ausgesperrt werden.
          </Typography>
          <p class="seal"><a href="https://datenschutz-generator.de/" title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p>
        </Box>
        <Footer />
      </Container>
    </>
  );
};

export default ImprintPage;
