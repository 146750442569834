import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Typography, Container, Box } from '@mui/material';
import TopBar from './TopBar';
import FAQ from './FAQ';
import Footer from './Footer';
import PaymentForm from './PaymentForm';
import { cfg } from './util';

const stripePromise = loadStripe(cfg('REACT_APP_STRIPE_PUBLIC_KEY'));

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, memberid, address, zip, city, email, letterType, verein, vereinId, cost } = location.state || {};

  useEffect(() => {
    if (!name || !memberid || !address || !zip || !city || !email || !letterType || !verein || !vereinId || !cost) {
      navigate('/');
    }
  }, [name, memberid, address, zip, city, email, letterType, verein, vereinId, cost, navigate]);

  if (!name || !memberid || !address || !zip || !city || !email || !letterType || !verein || !vereinId || !cost) {
    return null;
  }
  
  return (
    <>
      <TopBar />
      <Container maxWidth="md">
        <Box textAlign="center" py={5}>
          <Elements stripe={stripePromise} options={{locale: 'de'}}>
            <Typography variant="h4" gutterBottom align='left' mb={3}>
              Abschließen der Zahlung zur Kündigung des Vereins "{verein}"
            </Typography>
            <PaymentForm 
              name={name} 
              memberid={memberid} 
              address={address} 
              zip={zip} 
              city={city} 
              email={email} 
              letterType={parseInt(letterType)} 
              verein={verein} 
              vereinId={vereinId}
              cost={cost} 
            />
          </Elements>
        </Box>
        <FAQ />
        <Footer />
      </Container>
    </>
  );
};

export default PaymentPage;
