import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const VereinSelector = ({ vereine, value, onChange }) => {
  return (
    <Autocomplete
      fullWidth
      options={vereine.map(verein => verein.name)}
      value={value}
      onChange={(event, newValue) => onChange({ target: { value: newValue } })}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Verein"
          margin="normal"
        />
      )}
      disablePortal
      autoHighlight
      filterOptions={(options, { inputValue }) => {
        return options.filter(option =>
          option.toLowerCase().includes(inputValue.toLowerCase())
        );
      }}
    />
  );
};

export default VereinSelector;
