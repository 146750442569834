import React, { useState, useEffect } from 'react'
import { TextField, FormControl, Box, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { cfg, formatEuro } from './util'

const TerminateForm = ({ formData, vereinAddressValid, onInputChange, setFormValid }) => {
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (event) => {
    const { value } = event.target;
    onInputChange(event);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setEmailError('Bitte gib eine gültige E-Mail-Adresse ein');
      setFormValid(false);
    } else {
      setEmailError('');
      setFormValid(true);
    }
  };

  // Validate the form on every input change
  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(formData.email);
    setFormValid(isEmailValid);
  }, [formData.email, setFormValid]);

  return (
    <>
      <TextField
        fullWidth
        margin="normal"
        label="Deine Email-Adresse"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleEmailChange}
        error={!!emailError}
        helperText={emailError}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Dein Name und Vorname"
        name="name"
        value={formData.name}
        onChange={onInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Deine Vertrags- oder Mitgliedsnummer (optional) im Verein"
        name="memberid"
        value={formData.memberid}
        onChange={onInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Deine Straße mit Hausnummer"
        name="address"
        value={formData.address}
        onChange={onInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Deine Postleitzahl"
        name="zip"
        value={formData.zip}
        onChange={onInputChange}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Dein Wohnort"
        name="city"
        value={formData.city}
        onChange={onInputChange}
      />
      <FormControl component="fieldset" margin="normal">
        <Box mt={2}>
          <Typography variant="h5" gutterBottom>Variante der Kündigung</Typography>
        </Box>
        <RadioGroup
          name="letterType"
          value={formData.letterType}
          onChange={onInputChange}
        >
          {cfg('letterTypes').map((type, index) => (
            <FormControlLabel 
              key={index} 
              value={index} 
              control={<Radio />} 
              label={`${type.name} für ${formatEuro(type.priceEuro)}`}
              disabled={type.needsAddress ? !vereinAddressValid : false}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default TerminateForm;
