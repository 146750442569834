import React from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const TopBar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Box component={RouterLink} to="/" sx={{ flexGrow: 1, textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
          <img src="/logo/top-logo-csc-kuendigen.png" alt="CSC Kündigen .de" style={{ height: '40px' }} />
        </Box>
        <Box>
          <Button color="inherit" component={RouterLink} to="/">
            Startseite
          </Button>
          <Button color="inherit" component={RouterLink} to="/vereine">
            Vereine
          </Button>
          <Button color="inherit" component={RouterLink} to="/siegel">
            Siegel
          </Button>
          <Button color="inherit" component={HashLink} smooth to="/#formular">
            Kündigung
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
